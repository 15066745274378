<template>
  <div
    :class="{
      'cc-segment': true,
      'bg-red-8': !available,
      'bg-grey': inThePast
    }"
    :style="`
      width: ${width}%;
      border-left: 1px dashed ${$vnode.key % hide === 0 ? 'grey' : 'transparent'}
    `"
  >
    <div v-if="$vnode.key % hide === 0 || $vnode.key === 0" class="cc-block-time">
      {{ time }}
    </div>
  </div>
</template>

<script>
import date from 'utils/date-time'
export default {
  props: ['startTime', 'available', 'width', 'hide'],
  computed: {
    time () {
      return date.toCivilTime(this.startTime)
    },
    inThePast () {
      return date.newDate(this.startTime) < date.newDate()
    }
  }
}
</script>

<style lang="stylus">
.cc-segment
  height 48px
  display inline-block
  position relative

.cc-segment:first-child
  border-left 0!important

.cc-block-time
  position absolute
  top: -17px;
  color: #A0A0A0;
  font-size: 12px;
  left: -14px;
</style>
