<template>
  <div
    id="selection-bar"
    :style="`
      left: calc(${offset}% - 1.5px);
      width: ${width}%;
    `"
    :class="notFullyAvailable ? 'unavailable' : 'available'"
    class="selection-bar shadow-1"
    @mousedown="getMinutes(slotSizeInMinutes, blockWidth, updateDates, $event)"
    @touchstart="getMinutes(slotSizeInMinutes, blockWidth, updateDates, $event)"
  >
    <div class="start-date">
      {{ startTime }}
    </div>
    <div class="end-date">
      {{ endTime }}
    </div>
    <div
      class="start"
      @mousedown="getMinutes(slotSizeInMinutes, blockWidth, updateStart, $event)"
      @touchstart="getMinutes(slotSizeInMinutes, blockWidth, updateStart, $event)"
    >
      <q-icon
        :class="notFullyAvailable ? 'text-amber-10' : 'text-green-10'"
        name="drag_handle"
        class="extend-handle"
      />
    </div>
    <small>{{ readableBookingLength }}</small>
    <div
      class="end"
      @mousedown="getMinutes(slotSizeInMinutes, blockWidth, updateEnd, $event)"
      @touchstart="getMinutes(slotSizeInMinutes, blockWidth, updateEnd, $event)"
    >
      <q-icon
        :class="notFullyAvailable ? 'text-amber-10' : 'text-green-10'"
        name="drag_handle"
        class="extend-handle"
      />
    </div>
  </div>
</template>

<script>
import date from 'utils/date-time'

function getClientX (e) {
  if (e.clientX) return e.clientX
  else if (e.touches) return e.touches[0].clientX
}

function getMinutes (minsPerBlock, blockSize, cb, e) {
  let originPosition
  const area = document.getElementById('selection-bar').parentElement
  e.stopPropagation()
  originPosition = getClientX(e)

  e.preventDefault()

  function elementDrag (e) {
    e.preventDefault()
    const movement = getClientX(e) - originPosition
    const movementPercentage = (movement / area.clientWidth) * 100

    if (movementPercentage >= blockSize ||
        movementPercentage <= -blockSize
    ) {
      originPosition = getClientX(e)
      const percentageMovementRounded = (Math.round(movementPercentage / blockSize) * blockSize)
      const estimatedMinutes = (percentageMovementRounded / blockSize) * minsPerBlock
      const minutes = Math.round(estimatedMinutes / minsPerBlock) * minsPerBlock

      // eslint-disable-next-line standard/no-callback-literal
      cb(minutes)
    }
  }

  function closeDragElement () {
    document.onmouseup = null
    document.onmousemove = null
    document.removeEventListener('touchend', closeDragElement, { passive: false })
    document.removeEventListener('touchmove', elementDrag, { passive: false })
  }

  document.onmouseup = closeDragElement
  document.onmousemove = elementDrag

  document.addEventListener('touchend', closeDragElement, { passive: false })
  document.addEventListener('touchmove', elementDrag, { passive: false })
}

export default {
  props: {
    value: {
      type: Object,
      required: true,
      validator (obj) {
        return obj.start !== undefined && obj.end !== undefined
      }
    },
    blockWidth: Number,
    slots: Array,
    min: Object,
    max: Object,
    slotSizeInMinutes: Number,
    unavailableSlots: Array,
    notFullyAvailable: Boolean
  },
  computed: {
    startTime () {
      return date.toCivilTime(this.value.start)
    },
    endTime () {
      return date.toCivilTime(this.value.end)
    },
    readableBookingLength () {
      const diff = date.getDateDiff(this.value.end, this.value.start, ['days', 'hours', 'minutes'])

      const days = diff.days ? diff.days + ' days' : ''
      const hours = diff.hours ? diff.hours + ' hours' : ''
      const minutes = diff.minutes ? diff.minutes + ' mins' : ''

      return days +
            ((days && hours) || (days && minutes) ? ', ' : '') +
            hours +
            (hours && minutes ? ', ' : '') +
            minutes
    },
    offset () {
      const vm = this
      const slotsBefore = this.slots.filter(s => {
        const slotDate = date.newDate(s.period_start.date)
        return slotDate < vm.value.start
      })
      return slotsBefore.length * this.blockWidth
    },
    width () {
      const mins = date.getDateDiff(this.value.end, this.value.start, 'minutes').toObject().minutes
      const slots = mins / this.slotSizeInMinutes
      return slots * this.blockWidth
    }
  },
  methods: {
    updateDates (mins) {
      const start = date.adjustDate(this.value.start, { minutes: this.value.start.minute + mins })
      const end = date.adjustDate(this.value.end, { minutes: this.value.end.minute + mins })
      if (start >= this.min && end <= this.max) {
        this.$emit('input', { start, end })
      }
    },
    updateEnd (mins) {
      const end = date.adjustDate(this.value.end, { minutes: this.value.end.minute + mins })
      if (
        end <= this.max &&
        date.getDateDiff(end, this.value.start, 'minutes').toObject().minutes >= 30
      ) {
        this.$emit('input', { start: this.value.start, end })
      }
    },
    updateStart (mins) {
      const start = date.adjustDate(this.value.start, { minutes: this.value.start.minute + mins })
      if (
        start >= this.min &&
        date.getDateDiff(this.value.end, start, 'minutes').toObject().minutes >= 30
      ) {
        this.$emit('input', { start, end: this.value.end })
      }
    },
    getMinutes
  }
}
</script>

<style lang="stylus" scoped>
.selection-bar
  position: absolute;
  display grid
  height: 48px;
  top: -2px;
  user-select none
  box-sizing: content-box;
  cursor grab
  grid-template-columns 15px auto 15px

  > small
    margin: auto;
    text-align: center;
    color white

.start, .end
  height 100%
  cursor col-resize

.extend-handle
  transform rotate(90deg)
  font-size: 36px;
  position: absolute;
  width: 10px;
  top: -5px;

.start
  left 0
  .extend-handle
    left: 3px;

.end
  right 0
  .extend-handle
    right: 3px;

.unavailable
  background rgba(255, 160, 0, .9)
  border 2px solid rgba(255, 160, 0, .9)

.available
  background rgba(56, 142, 60, .9)
  border 2px solid rgba(56, 142, 60, .9)

.start-date
  position: absolute;
  top: -28px;
  left: -18px;
  font-size: 16px;
  color: black;
  background: white

.end-date
  position: absolute;
  top: -28px;
  right: -20px;
  font-size: 16px;
  color: black;
  background: white
</style>
