<template>
  <q-page padding>
    <div v-if="summary" class="row q-col-gutter-xs">
      <div class="col-xs-12">
        <q-card class="bg-white full-width">
          <q-card-section :class="`bg-primary text-white`">
            {{ $t('booking.summary') }}
            <m-image
              :src="require('assets/enterprise_carclub_white.png')"
              width="100px"
              class="float-right"
            />
          </q-card-section>
          <q-card-section>
            <div class="row gutter-sm" style="margin-top: 16px">
              <div class="col-4">
                <sub>{{ $tc('vehicle') }}</sub>
                <h6 class="text-grey-9">
                  {{ summary.vehicle.make }}
                  <br>
                  {{ summary.vehicle.model }}
                </h6>
              </div>
              <div class="col-3">
                <m-image
                  :src="summary.vehicle.image"
                  :fallback="fallback"
                  width="142"
                  height="88"
                />
              </div>
            </div>

            <div class="row q-col-gutter-sm" style="margin-top: 16px">
              <div class="col-6">
                <sub>{{ $t('pick_up') }}</sub>
                <h6 class="text-grey-9">
                  {{ summary.start.date | date }}
                </h6>
                {{ summary.vehicle.location.description }}
              </div>
              <div class="col-6">
                <sub>{{ $t('drop_off') }}</sub>
                <h6 class="text-grey-9" />
                <h6 class="text-grey-9">
                  {{ summary.end.date | date }}
                </h6>
              </div>
            </div>
            <q-separator class="hr" />
            <div
              v-for="(item, i) in summary.order_items"
              :key="i"
              class="order-item"
            >
              <div class="description">
                {{ item.description }}
              </div>
              <div class="grid">
                <q-item>
                  <q-item-section>
                    <q-item-label label>
                      {{ $t('net_amount') }}
                    </q-item-label>
                    <q-item-label class="text-faded text-body2">
                      {{ item.net_amount.text }}
                    </q-item-label>
                  </q-item-section>
                </q-item>
                <q-item>
                  <q-item-section>
                    <q-item-label label>
                      {{ $t('tax.amount') }}
                    </q-item-label>
                    <q-item-label class="text-faded text-body2">
                      {{ item.tax_amount.text }}
                    </q-item-label>
                  </q-item-section>
                </q-item>
                <q-item>
                  <q-item-section>
                    <q-item-label label>
                      {{ $t('total_cost') }}
                    </q-item-label>
                    <q-item-label class="text-faded text-body2">
                      {{ item.total.text }}
                    </q-item-label>
                  </q-item-section>
                </q-item>
              </div>
            </div>

            <q-separator class="hr" />
            <h6 class="row text-bold">
              <span class="offset-5 col-4 text-grey-9 text-right">{{
                $t('total_cost')
              }}</span>
              <span class="col-3 text-grey-9 text-right">{{
                summary.total_amount.text
              }}</span>
            </h6>
          </q-card-section>
        </q-card>
      </div>
      <div class="col-xs-12">
        <m-banner
          :title="$t('information')"
          :message="$t('carclub.charges_estimate')"
          color="primary"
        />
      </div>
      <div class="col-xs-12">
        <m-attach-to-booking-request
          :suggested="summary.suggested_name"
          :username="traveller.value"
          :journey="journey"
          :show-travel-type="
            travelPolicy && travelPolicy.carclub.enabled === true
          "
          @change="(val) => $store.dispatch('carClub/setJourney', val)"
          @reasonChange="(val) => $store.dispatch('carClub/setReason', val)"
          @labelChange="(val) => $store.dispatch('carClub/setLabels', val)"
          @travelTypeChange="(val) => (travelType = val)"
        />
      </div>
      <div class="col-xs-12">
        <m-banner
          :message="$t('payment_will_be_taken_by', { company: 'Enterprise' })"
          color="primary"
        />
      </div>
      <payment-buttons
        :total-amount="summary.total_amount.amount"
        :requester="requester"
        :traveller="traveller || requester"
        :booking-token="summary.booking_token"
        :reason-for="reason_for"
        :journey="journey"
        :labels="labels"
        :supplier-handled="true"
        :travel-type="
          (travelPolicy && travelPolicy.carclub.enabled === true) ||
            !organisation
            ? travelType
            : 'Private'
        "
      />
      <div
        class="text-faded q-px-lg text-center text-caption"
        v-html="
          $t('mobilleo_privacy_and_terms_carclub', {
            privacyURL: partner.privacyURL,
            termsURL: partner.termsURL,
            carClubTerms: carClubTermsURL,
          })
        "
      />
    </div>
  </q-page>
</template>

<script type="text/javascript">
import { mapGetters } from 'vuex'
import authentication from 'mixins/authentication'
import date from 'utils/date-time'
import loading from 'utils/loading'
import paymentButtons from 'pages/payments/payment-method-buttons'
import store from 'store'
import i18n from 'i18n'
import { MImage, MBanner, MAttachToBookingRequest } from 'components/'
import { handleErrors } from 'utils/utils'

export default {
  name: 'OndemandCarClubSummary',
  filters: {
    date: value => date.toMediumDateTime(value)
  },
  components: {
    paymentButtons,
    MImage,
    MBanner,
    MAttachToBookingRequest
  },
  mixins: [authentication],
  data () {
    return {
      fallback: require('assets/blank-vehicle-hire.png'),
      privacyURL: 'https://www.fodmobilitygroup.com/wp-content/uploads/2023/02/FOD-Mobility-Group-Privacy-Policy-23.pdf',
      termsURL: 'https://mobilleo.s3.eu-west-2.amazonaws.com/terms.pdf',
      travelType: null
    }
  },
  computed: {
    ...mapGetters({
      summary: 'carClub/summary',
      traveller: 'carClub/traveller',
      requester: 'carClub/requester',
      journey: 'carClub/journey',
      reason_for: 'carClub/reason_for',
      labels: 'carClub/labels',
      partner: 'partner',
      organisation: 'organisation'
    }),
    carClubTermsURL: () =>
      'https://www.enterprisecarclub.co.uk/gb/en/terms/terms-and-conditions-corp.html',
    travelPolicy () {
      if (!this.organisation) return null
      if (this.organisation?.attributes?.travel_policy) {
        return this.organisation.attributes.travel_policy
      }
      return { carclub: { enabled: true } }
    }
  },
  async beforeRouteEnter (to, from, next) {
    const partner = store.getters['partner']

    loading.start({
      message: i18n.t('loading.confirming.carclub_booking'),
      partnerSlug: partner.slug,
      spinnerColor: partner.hex
    })

    try {
      await store.dispatch('carClub/getSummary')
      next(() => loading.stop())
    } catch (err) {
      handleErrors(err)
      next(false)
      loading.stop()
    }
  }
}
</script>

<style lang="stylus" scoped>
span, .hr
  margin-top 16px

button
  width 100%

.summary-main
  padding 20px

.grid
  display grid
  grid-template-columns repeat(auto-fill, 33%)
  font-weight 400
  align-items baseline

.order-item
  padding 10px

.grid > div
  padding 10px 0

.grid > div:nth-child(2)
  text-align center

.grid > div:nth-child(3)
  text-align right

.description
  font-weight 700

h6.text-bold
  padding 0 10px

.loading
  position absolute
  top 50%
  left 50%
  transform translate(-50%, -50%)

.loading p
  text-align center
  margin-top 16px

.loader
  width 100px
  height 100px
  margin auto

a
  font-weight 700
</style>
