<template>
  <div class="magic-bar">
    <div id="visual" class="visual">
      <availability-bar
        :slots="displaySlots"
        :block-width="availabilityBlockWidth"
        :slot-size-in-minutes="slotSizeInMinutes"
        :hide="factor"
      />
      <selection-bar
        v-model="dates"
        :block-width="availabilityBlockWidth"
        :slots="displaySlots"
        :min="min"
        :max="max"
        :slot-size-in-minutes="15"
        :unavailable-slots="unavailableSlots"
        :not-fully-available="notFullyAvailable"
      />
    </div>
  </div>
</template>

<script>
import date from 'utils/date-time'
import availabilityBar from './availability-bar.vue'
import selectionBar from './selection-bar.vue'
import { mapGetters } from 'vuex'

const factors = number => Array
  .from(Array(number + 1), (_, i) => i)
  .filter(i => number % i === 0)

export default {
  components: { availabilityBar, selectionBar },
  props: {
    availabilities: Array,
    value: {
      type: Object,
      required: true,
      validator (obj) {
        return obj.start !== undefined && obj.end !== undefined
      }
    },
    min: Object,
    max: Object
  },
  computed: {
    ...mapGetters({
      unavailableSlots: 'carClub/unavailableSlotsForSelection',
      notFullyAvailable: 'carClub/notFullyAvailable'
    }),
    factor () {
      const f = factors(this.displaySlots.length)
      return f[parseInt(f.length / 2)]
    },
    dates: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    displaySlots () {
      return this.availabilities.filter(pa => {
        const slotDate = date.newDate(pa.period_start.date)
        return slotDate >= this.min && slotDate < this.max
      })
    },
    availabilityBlockWidth () {
      return 100 / this.displaySlots.length
    },
    slotSizeInMinutes () {
      const size = (this.factor * 15 % 2 === 0
        ? this.factor * 15
        : this.factor * 30) / 2

      return Math.ceil(size / 15) * 15
    }
  }
}
</script>

<style lang="stylus" scoped>
.visual
  position relative
  margin: 20px 10px;
  height 50px
  border 1px solid black

</style>
