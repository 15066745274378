<template>
  <q-page class="page bg-white column items-center no-wrap">
    <div class="code" :class="$store.getters.partner.slug" />
    <div class="column container">
      <div class="row">
        <q-card square>
          <q-card-section class="bg-primary text-white text-uppercase">
            {{ $t('content_type.carclub') }}
            <m-image :src="require('assets/enterprise_carclub_white.png')" width="100px" class="float-right" />
          </q-card-section>
          <q-separator />
          <q-card-section class="column">
            <on-demand-form v-if="canCarClub" />
            <div v-else class="notice">
              <h6>{{ $t('carclub.credentials.required_2') }}</h6>
              <q-btn
                :label="$t('carclub.membership.sign_in')"
                color="primary"
                :to="{hash: '/user-settings/carclub'}"
              />
              <h6 v-if="['gohi'].includes(partner.slug)">
                {{ $t('carclub.membership.register_here') }}
              </h6>
              <h6 v-else>
                {{ $t('carclub.membership.register_directly') }}
              </h6>
              <q-btn
                v-if="partner.pages.carclub"
                :label="$t('carclub.membership.sign_up')"
                :color="partner.pages.carclub.button.color"
                @click="openURL(`${partner.pages.carclub.button.url}`)"
              />
            </div>
          </q-card-section>
        </q-card>
      </div>
      <div class="row">
        <div class="col">
          <m-unified-users v-if="canCarClub && $q.platform.is.desktop" v-model="people" />
        </div>
      </div>
      <div class="cta">
        <m-cta-button
          v-if="canCarClub"
          :label="$tc('find.car')"
          :disabled="disabled"
          @ctaClick="getResults"
        />
      </div>
    </div>
  </q-page>
</template>

<script type="text/javascript">
import authentication from 'mixins/authentication'
import { openURL } from 'quasar'
import { MImage, MUnifiedUsers, MCtaButton } from 'components/'
import { required } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import onDemandForm from './form.vue'
import date from 'utils/date-time'
import 'styles/ondemand.styl'

const { addToDate, roundMinutesUp } = date

export default {
  components: {
    onDemandForm,
    MUnifiedUsers,
    MImage,
    MCtaButton
  },
  mixins: [authentication],
  computed: {
    ...mapGetters({
      userLookup: 'userLookup',
      searchLocation: 'carClub/searchLocation',
      searchStartDateTime: 'carClub/searchStartDateTime',
      searchEndDateTime: 'carClub/searchEndDateTime',
      traveller: 'carClub/traveller',
      requester: 'carClub/requester',
      partner: 'partner'
    }),
    people: {
      get () {
        return {
          requester: this.requester,
          traveller: this.traveller
        }
      },
      set ({ requester, traveller }) {
        this.$store.dispatch('carClub/setRequester', requester)
        if (traveller && !traveller.has_carclub) {
          this.$q.dialog({
            title: this.$t('carclub.credentials.not_set'),
            message: this.$t('person_needs_to_add_credentials', { person: traveller.label })
          })
          traveller = null
        }
        this.$store.dispatch('carClub/setTraveller', traveller)
      }
    },
    disabled () {
      this.$v.$touch()
      return this.$v.$error
    },
    canCarClub () {
      return this.userLookup.has_carclub || (this.$q.platform.is.desktop && this.hasPermission('can.book.for.org.members'))
    },
    travellerCanCarClub () {
      return this.people.traveller && this.people.traveller.has_carclub
    }
  },
  created () {
    const searchLocation = this.$route.params.origin || this.$route.query.origin
    this.$store.dispatch('carClub/reset')
    if (typeof searchLocation === 'object') {
      this.$store.dispatch('carClub/setSearchLocation', searchLocation)
    }
    this.$store.dispatch('carClub/setSearchStartDateTime', roundMinutesUp(addToDate(date.newDate(), { minutes: 0 }), 15))
    this.$store.dispatch('carClub/setSearchEndDateTime', roundMinutesUp(addToDate(this.searchStartDateTime, { minutes: 30 })))
  },
  methods: {
    openURL,
    getResults () {
      this.$v.$touch()
      if (!this.$v.$error) {
        this.$router.push('/on-demand/carclub/results')
      } else {
        this.$q.notify({ type: 'negative', message: this.$t('input.pickup_location') })
      }
    }
  },
  validations: {
    searchLocation: { required },
    searchStartDateTime: { required },
    searchEndDateTime: { required },
    requester: { required },
    traveller: { 'has_carclub': { isTrue: (value) => value === true } }
  }
}
</script>

<style lang="stylus" scoped>

.notice
  display grid
  grid-auto-rows auto
  grid-row-gap 20px
  text-align center

  *
    margin auto
</style>
