<template>
  <q-page padding>
    <div v-if="results" class="search-results">
      <m-group-title
        :label="$t('carclub.closest_vehicles')"
        columns="null"
      />
      <result-card
        v-for="(r, i) in results"
        :key="i"
        :vehicle="r"
        :selected="vehicle && (vehicle.plate === r.plate)"
        :start="searchStartDateTime"
        :end="searchEndDateTime"
        @selected="val => vehicle = val"
      />
    </div>
    <div class="cta">
      <m-cta-button
        :label="selectedVehicle ? $t('continue') : $t('select_an_option_to_continue')"
        :disabled="!selectedVehicle"
        @ctaClick="$router.push('/on-demand/carclub/summary')"
      />
    </div>
  </q-page>
</template>

<script>
import { mapGetters } from 'vuex'
import { MGroupTitle, MCtaButton } from 'components/'
import resultCard from './result-card.vue'
import loading from 'utils/loading'
import store from 'store'
import i18n from 'i18n'
import { handleErrors } from 'utils/utils'

export default {
  components: {
    resultCard,
    MGroupTitle,
    MCtaButton
  },
  computed: {
    ...mapGetters({
      results: 'carClub/availabilityResults',
      selectedVehicle: 'carClub/selectedVehicle',
      searchStartDateTime: 'carClub/searchStartDateTime',
      searchEndDateTime: 'carClub/searchEndDateTime'
    }),
    vehicle: {
      get () {
        return this.selectedVehicle
      },
      set (val) {
        this.$store.dispatch('carClub/setSelectedVehicle', val)
      }
    }
  },
  async beforeRouteEnter (to, from, next) {
    const partner = store.getters.partner

    loading.start({
      message: i18n.t('loading.searching.carclub'),
      partnerSlug: partner.slug,
      spinnerColor: partner.hex
    })

    try {
      await store.dispatch('carClub/search')
      next(() => loading.stop())
    } catch (err) {
      handleErrors(err)
      next(false)
      loading.stop()
    }
  }
}
</script>

<style lang="stylus" scoped>
.search-results
  max-width 768px
  margin auto

.loading
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

.loader
  width: 100px
  height: 100px
  margin: auto

h1
  font-size: 25px;
  line-height: 2;
</style>
