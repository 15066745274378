<template>
  <q-page>
    <div class="layout-padding">
      <div>
        <q-card class="bg-white">
          <q-card-section>
            <div class="row">
              <div class="col-xs-12">
                <header>
                  <h1>{{ $t('availability_window') }}</h1>
                  <p class="mobile-hide">
                    {{ $t('carclub.adjust_slider') }}
                  </p>
                </header>
                <div class="dates-container text-center">
                  <div class="start-time">
                    {{ dates.start | date }}<br>
                    <p class="time">
                      <q-btn
                        v-if="$q.platform.is.mobile"
                        flat
                        style="width:100%"
                        label="+"
                        @click="alterStart(15)"
                      />
                      {{ dates.start | time }}
                      <q-btn
                        v-if="$q.platform.is.mobile"
                        flat
                        style="width:100%"
                        label="-"
                        @click="alterStart(-15)"
                      />
                    </p>
                  </div>
                  <div class="arrow">
                    <q-icon name="arrow_forward" size="36px" />
                  </div>
                  <div class="start-time">
                    {{ dates.end | date }}<br>
                    <p class="time">
                      <q-btn
                        v-if="$q.platform.is.mobile"
                        flat
                        style="width:100%"
                        label="+"
                        @click="alterEnd(15)"
                      />
                      {{ dates.end | time }}
                      <q-btn
                        v-if="$q.platform.is.mobile"
                        flat
                        style="width:100%"
                        label="-"
                        @click="alterEnd(-15)"
                      />
                    </p>
                  </div>
                </div>
                <div class="window">
                  <magic-bar
                    v-model="dates"
                    :availabilities="selectedVehicle.availability"
                    :min="windowMinTime"
                    :max="windowMaxTime"
                    class="mobile-hide"
                  />
                </div>
                <div class="text-center mobile-hide">
                  {{ $t('carclub.expand_view') }}
                </div>
                <div />
              </div>
            </div>
          </q-card-section>
        </q-card>
        <q-card class="bg-white">
          <div class="vehicle-info row q-col-gutter-x-sm">
            <div class="col-xs-8 col-sm-6 col-sm-3">
              <h1>{{ selectedVehicle.colour }} {{ selectedVehicle.make }} {{ selectedVehicle.model }}</h1>
            </div>
            <div class="col-xs-4 col-sm-6">
              <img :src="require('assets/enterprise_carclub.png')" width="100%" class="float-right" style="max-width: 200px">
            </div>
            <div class="col-xs-8 col-sm-12">
              <p>{{ $t('carclub.with_transmissiontype', { transmissiontype: selectedVehicle.transmission }) }}</p>
              <small>{{ $t('enterprise_bay_place', { place: selectedVehicle.location.description }) }}</small>
            </div>
            <div class="col-xs-4 col-sm-6">
              <m-image
                :src="selectedVehicle.image"
                :fallback="fallback"
              />
            </div>
            <div class="col-xs-12 col-sm-6">
              <m-gmaps-static
                :zoom="15"
                :width="640"
                :height="300"
                :center="selectedVehicle.location.description"
                :markers="[selectedVehicle.location.latitude, selectedVehicle.location.longitude].join(',')"
                map-style="feature:administrative.land_parcel%7Cvisibility:off&style=feature:administrative.neighborhood%7Cvisibility:off&style=feature:poi%7Celement:labels.text%7Cvisibility:off&style=feature:poi.business%7Cvisibility:off&style=feature:road%7Celement:labels%7Cvisibility:off&style=feature:road%7Celement:labels.icon%7Cvisibility:off&style=feature:road.arterial%7Cvisibility:off&style=feature:road.highway%7Celement:labels%7Cvisibility:off&style=feature:road.local%7Cvisibility:off&style=feature:transit%7Cvisibility:off&style=feature:water%7Celement:labels.text%7Cvisibility:off"
              />
            </div>
          </div>
        </q-card>
      </div>
    </div>
    <q-footer v-if="$q.platform.is.mobile" class="bg-white shadow-5 q-pa-sm q-py-md row justify-around secondary-footer">
      <q-btn
        :label="!notFullyAvailable ? $t('continue') : $t('select_an_option_to_continue')"
        color="primary"
        size="lg"
        class="full-width"
        :disable="notFullyAvailable"
        @click="$router.push({name: 'ondemand-carclub-summary', params: {token: selectedVehicle.token}})"
      />
    </q-footer>
  </q-page>
</template>

<script>
import { mapGetters } from 'vuex'
import date from 'utils/date-time.js'
import magicBar from './magic-bar/index.vue'
import { MImage, MGmapsStatic } from 'components/'

export default {
  components: { magicBar, MImage, MGmapsStatic },
  filters: {
    time (data) {
      return date.toCivilTime(data)
    },
    date (data) {
      return date.toMediumDate(data)
    },
    shortDate (data) {
      return date.toShortDateTime(data)
    }
  },
  data () {
    return {
      fallback: require('assets/blank-vehicle-hire.png')
    }
  },
  computed: {
    ...mapGetters({
      searchStartDateTime: 'carClub/searchStartDateTime',
      searchEndDateTime: 'carClub/searchEndDateTime',
      refinedStartDateTime: 'carClub/refinedStartDateTime',
      refinedEndDateTime: 'carClub/refinedEndDateTime',
      selectedVehicle: 'carClub/selectedVehicle',
      windowMinTime: 'carClub/windowMinTime',
      windowMaxTime: 'carClub/windowMaxTime',
      notFullyAvailable: 'carClub/notFullyAvailable'
    }),
    dates: {
      get () {
        return {
          start: this.refinedStartDateTime,
          end: this.refinedEndDateTime
        }
      },
      set ({ start, end }) {
        this.$store.dispatch('carClub/setRefinedStartDateTime', start)
        this.$store.dispatch('carClub/setRefinedEndDateTime', end)
      }
    },
    startLabel () {
      return date.toCivilDateTime(this.refinedStartDateTime)
    },
    endLabel () {
      return date.toCivilDateTime(this.refinedEndDateTime)
    }
  },
  methods: {
    alterStart (mins) {
      const start = date.adjustDate(this.dates.start, { minutes: this.dates.start.minute + mins })

      const diff = date.getDateDiff(this.dates.end, start, 'minutes')
      if (diff.values.minutes >= 30) {
        this.dates = { start, end: this.dates.end }
      } else {
        this.$q.notify(this.$t('carclub.minimum_time_window'))
      }
      if (this.notFullyAvailable) {
        this.$q.notify(this.$t('error.booking_must_be_future', { num: 15 }))
      }
    },
    alterEnd (mins) {
      const end = date.adjustDate(this.dates.end, { minutes: this.dates.end.minute + mins })

      const diff = date.getDateDiff(end, this.dates.start, 'minutes')

      if (diff.values.minutes >= 30) {
        this.dates = { end, start: this.dates.start }
      } else {
        this.$q.notify(this.$t('carclub.minimum_time_window'))
      }
    }
  }
}
</script>

<style lang="stylus" scoped>

.layout-padding
  @media (max-width: 1199px)
    padding 1em

.window *
  user-select: none

header
  margin-bottom 16px
h1
  font-size 24px
  font-weight 400
  line-height 2
.vehicle-info
  padding: 16px
.center
  display grid

  > *
    margin auto

.window-limit
  font-size: 13px
  text-align center

.dates-container
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  max-width: 600px;
  margin: auto;
  padding-bottom: 10px;

.arrow
  display grid
  margin auto

.time
  font-size: 24px
</style>
