<template>
  <div class="availability-bar">
    <availability-block
      v-for="(b, i) in slots"
      :key="i"
      :start-time="b.period_start.date"
      :available="b.is_available"
      :width="blockWidth"
      :hide="hide"
    />
    <div class="cc-end-time">
      {{ endTime }}
    </div>
  </div>
</template>

<script>
import availabilityBlock from './availability-block.vue'
import date from 'utils/date-time'

export default {
  components: { availabilityBlock },
  props: ['slots', 'blockWidth', 'slotSizeInMinutes', 'hide'],
  computed: {
    endTime () {
      return date.toCivilTime(date.addToDate(this.slots[this.slots.length - 1].period_start.date, { minutes: 15 }))
    }
  }
}
</script>

<style lang="stylus" scoped>
.cc-end-time
  position absolute
  top: -17px;
  color: #A0A0A0;
  font-size: 12px;
  right: -14px;
</style>
